import {
    GLOBAL_SETTINGS,
} from "../actions/Actions";

const initialState = {
    usd: 0,
    euro: 0,
    minSummOrder: 0,
    freeDeliveryFrom: 0,
    availableSite: 1,
    deliveryPrice: 0,
    adminEmail: "",
    adminPhone: ""
};

export default function (state = initialState, action) {
    switch (action.type) {

        case GLOBAL_SETTINGS:
            const { usd, euro, minSummOrder, freeDeliveryFrom, deliveryPrice, adminEmail, adminPhone, availableSite } = action.payload
            return Object.assign({}, {
                ...state,
                usd,
                euro,
                minSummOrder,
                freeDeliveryFrom,
                deliveryPrice,
                adminEmail,
                adminPhone,
                availableSite: Number(availableSite)
            });

        default:
            return state;
    }
}
